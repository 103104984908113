import React from 'react'

const Save = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.25 5.83398V14.834C16.25 15.2465 16.1031 15.5996 15.8094 15.8934C15.5156 16.1871 15.1625 16.334 14.75 16.334H4.25C3.8375 16.334 3.48438 16.1871 3.19063 15.8934C2.89687 15.5996 2.75 15.2465 2.75 14.834V4.33398C2.75 3.92148 2.89687 3.56836 3.19063 3.27461C3.48438 2.98086 3.8375 2.83398 4.25 2.83398H13.25L16.25 5.83398ZM14.75 6.47148L12.6125 4.33398H4.25V14.834H14.75V6.47148ZM9.5 14.084C10.125 14.084 10.6563 13.8652 11.0938 13.4277C11.5312 12.9902 11.75 12.459 11.75 11.834C11.75 11.209 11.5312 10.6777 11.0938 10.2402C10.6563 9.80273 10.125 9.58398 9.5 9.58398C8.875 9.58398 8.34375 9.80273 7.90625 10.2402C7.46875 10.6777 7.25 11.209 7.25 11.834C7.25 12.459 7.46875 12.9902 7.90625 13.4277C8.34375 13.8652 8.875 14.084 9.5 14.084ZM5 8.08398H11.75V5.08398H5V8.08398ZM4.25 6.47148V14.834V4.33398V6.47148Z" fill="white" />
        </svg>

    )
}

export default Save