import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { MdOutlineLogin } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(loginUser: { email: $email, password: $password }) {
      accessToken
    }
  }
`;
const Auth = () => {
  const navigate = useNavigate();
  const [login, { loading }] = useMutation(LOGIN_MUTATION);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      navigate('/create-municipality');
    }
  }, [navigate]);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Required'),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const { data } = await login({
          variables: { email: values.email, password: values.password },
        });
        localStorage.setItem('accessToken', data.login.accessToken);
        navigate('/create-municipality');
      } catch (error) {
        console.error('Error logging in:', error);
        setErrors({ submit: 'Invalid email or password' });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="flex flex-col justify-center items-center flex-1 h-screen bg-gray-100">
      <div className="flex flex-col items-center">
        <img
          src={require('../assets/enforceLogo.png')}
          className="w-32 h-32 object-contain"
          alt="Enforce logo"
        />
        <span className="text-4xl font-bold my-4">Super Admin</span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="px-9 py-6 border border-gray-300 bg-gray-50 rounded-md shadow-md">
          <div className="mb-2">
            <input
              className={`focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent w-80 h-12 rounded-md px-4 border text-sm bg-white ${
                formik.touched.email && formik.errors.email
                  ? 'border-red-500'
                  : 'border-gray-300'
              }`}
              type="email"
              name="email"
              placeholder="Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
          </div>
          <div className="mb-2">
            <input
              className={`focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent w-80 h-12 rounded-md px-4 border text-sm bg-white ${
                formik.touched.password && formik.errors.password
                  ? 'border-red-500'
                  : 'border-gray-300'
              }`}
              type="password"
              name="password"
              placeholder="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
          </div>
          {formik.errors.submit && (
            <div className="my-2 text-xs text-red-500">
              {formik.errors.submit}
            </div>
          )}
          <button
            className="flex items-center justify-center font-semibold text-sm bg-gray-200 rounded-full w-full py-4 mt-9"
            type="submit"
            disabled={formik.isSubmitting || loading}
          >
            <MdOutlineLogin size={18} className="mr-2" />
            {formik.isSubmitting || loading ? 'Logging in...' : 'Login'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Auth;
