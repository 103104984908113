import React from 'react';

const Download = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 12.168L5.25 8.41797L6.3 7.33047L8.25 9.28047V3.16797H9.75V9.28047L11.7 7.33047L12.75 8.41797L9 12.168ZM4.5 15.168C4.0875 15.168 3.73438 15.0211 3.44063 14.7273C3.14687 14.4336 3 14.0805 3 13.668V11.418H4.5V13.668H13.5V11.418H15V13.668C15 14.0805 14.8531 14.4336 14.5594 14.7273C14.2656 15.0211 13.9125 15.168 13.5 15.168H4.5Z"
        fill="black"
      />
    </svg>
  );
};

export default Download;
