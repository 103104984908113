import React from 'react'

const Upload = () => {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.25 12.584V6.47148L6.3 8.42148L5.25 7.33398L9 3.58398L12.75 7.33398L11.7 8.42148L9.75 6.47148V12.584H8.25ZM4.5 15.584C4.0875 15.584 3.73438 15.4371 3.44063 15.1434C3.14687 14.8496 3 14.4965 3 14.084V11.834H4.5V14.084H13.5V11.834H15V14.084C15 14.4965 14.8531 14.8496 14.5594 15.1434C14.2656 15.4371 13.9125 15.584 13.5 15.584H4.5Z" fill="black" />
        </svg>

    )
}

export default Upload